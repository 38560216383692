import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Logos from "../components/logos";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { site, markdownRemark } = data; // data.markdownRemark holds your post data
    const { siteMetadata } = site;
    const { frontmatter, html } = markdownRemark;
    return (
        <Layout>
            <Helmet>
                <title>
                    {frontmatter.title} | {siteMetadata.title}
                </title>
                <meta name="description" content={frontmatter.metaDescription} />
            </Helmet>

            <div className="w-full mx-auto">
                <div className="py-10 sm:pt-16 lg:pt-8 lg:pb-8 lg:overflow-hidden bg-header">
                    <div className="mx-auto max-w-7xl px-4 lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div className=" sm:text-center px-4 lg:px-8 lg:text-left lg:flex lg:items-center">
                                <div className="lg:py-24">
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span className="text-base block font-semibold tracking-wide uppercase">Training</span>
                                        <span className="pb-3 block text-white sm:pb-5">We provide a variety of training options</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white my-10 sm:my-0">
                <div className="relative mx-auto max-w-7xl px-4 lg:px-8">
                    <div className="relative md:bg-white md:p-6">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                                <p
                                    className="max-w-2xl mt-5 mx-auto text-xl text-gray-500"
                                >
                                    Tim Benstead Associates Ltd can provide a range of training options to support your business or college. Tim spent over 16 years lecturing at a large further education college before working for the Electrical Safety Council and Certsure LLP (NICEIC/ELECSA).
                                </p>
                                <p
                                    className="max-w-2xl mt-5 mx-auto text-xl text-gray-500"
                                >He has developed courses in design, initial inspection and testing, periodic inspection and testing and other bespoke courses. Tim’s work with BSI and the IET on standards making gives him a unique insight that ensures any training is technically up-to-date and relevant.
                                </p>
                            </div>

                            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                                <p
                                    className="max-w-2xl mt-5 mx-auto text-xl text-gray-500"
                                >Having spent so long as a lecturer and then in a senior role with NICEIC/ELECSA Tim is able to deliver relevant and technically insightful training on a range of subjects as they relate to electrical installations complying with BS 7671, and fire detection and fire alarm systems complying with BS 5839-1 and BS 5839-6.
                                </p>
                                <p
                                    className="max-w-2xl mt-5 mx-auto text-xl text-gray-500"
                                >For businesses that require specific training to meet their needs, Tim Benstead Associates Ltd are able to provide a service that is second to none. Contact us for further information.
                                </p>
                                <div className="inline-flex rounded-md shadow">
                                    <Link to="/contact" className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue bg-white hover:bg-indigo-700">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Logos />
        </Layout>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        metaDescription
        intro
      }
    }
  }
`;
